.app {
  // background-color: #383435;
  background-color: #fff;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    height: 100%;
    overflow: hidden;
  }
}

.app__bg {
  width: 100%;
  height: 100%;
  background-size: contain;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.app__bg--video {
  object-fit: cover;
}

.app__opacity {
  background: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.app__logo {
  position: relative;
  margin: 0 auto;
  top: 1rem;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  opacity: 0;
  transition: 0.5s ease-in-out;
  width: 60px;

  &:hover {
    // transform: rotate(120deg);
    opacity: 0.8;
  }
}

.app__header {
}

.home__title {
  max-width: 100%;
  position: relative;
}

.nav--desktop .app__link {
  display: inline-block;
  overflow: hidden;
  &:before,
  &:after {
    right: 0;
    bottom: 0;
  }
  &:before {
    width: 100%;
    height: 1px;
    transform: translateX(-100%);
  }
  &:after {
    width: 1px;
    height: 100%;
    transform: translateY(100%);
  }
  > span {
    display: block;
    padding: 10px;
    &:before,
    &:after {
      left: 0;
      top: 0;
    }
    &:before {
      width: 100%;
      height: 1px;
      transform: translateX(100%);
    }
    &:after {
      width: 1px;
      height: 100%;
      transform: translateY(-100%);
    }
  }
  &:hover:before,
  &:hover:after,
  &:hover > span:before,
  &:hover > span:after {
    transform: translate(0, 0);
  }

  &.active {
    &:before,
    &:after,
    & > span:before,
    & > span:after {
      transform: translate(0, 0);
    }
  }
}

.nav--desktop .app__link,
.nav--desktop .app__link > span {
  text-decoration: none;
  line-height: 24px;
  &:before,
  &:after {
    content: "";
    position: absolute;
    background-color: #fff;
    transition: transform 0.5s ease;
  }
}

.app__link-wrapper {
  margin: 1rem;
}

.app__link {
  position: absolute;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  z-index: 1;

  &.mod--top-left {
    top: 1.75rem;
    left: 2rem;
  }
  &.mod--top-right {
    top: 1.75rem;
    right: 2rem;
  }
  &.mod--bottom-left {
    bottom: 1.75rem;
    left: 2rem;
  }
  &.mod--bottom-right {
    bottom: 1.75rem;
    right: 2rem;
  }
}

.nav--desktop {
  @media (max-width: 768px) {
    display: none;
  }
}

.nav--mobile {
  background: #000;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
  height: 0;
  transition: 1s ease;
  display: flex;
  opacity: 0;

  .app__link {
    margin: 1rem;
    position: relative;

    @media (max-width: 768px) {
      font-size: 3vmax;
    }
  }
}

.nav__toggle {
  position: absolute;
  top: 2.5rem;
  right: 1.5rem;

  @media (min-width: 769px) {
    display: none;
  }
}

.nav__icon {
  width: 35px;
  height: 25px;
  position: relative;
  margin: 0 auto;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 10;

  span {
    display: block;
    position: absolute;
    height: 1px;
    width: 100%;
    background: #fff;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  span:nth-child(1) {
    top: 0px;
  }

  span:nth-child(2),
  span:nth-child(3) {
    top: 10px;
  }

  span:nth-child(4) {
    top: 20px;
  }

  &.mod--open span:nth-child(1) {
    top: 10px;
    width: 0%;
    left: 50%;
  }

  &.mod--open span:nth-child(2) {
    transform: rotate(45deg);
  }

  &.mod--open span:nth-child(3) {
    transform: rotate(-45deg);
  }

  &.mod--open span:nth-child(4) {
    top: 10px;
    width: 0%;
    left: 50%;
  }
}

.page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;

  @media (max-width: 768px) {
    position: relative;
    min-height: calc(100vh - 78px);
  }
}

.page__overlay {
  position: absolute;
  top: 0;
  background-color: #000;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  outline: 1px solid transparent;
  // z-index: -1;

  &--topleft {
    right: -100%;
    transform: skewX(-210deg) scale(1);
  }

  &--topright {
    right: 100%;
    transform: skewX(210deg) scale(1);
  }

  &--bottomleft {
    right: -100%;
    transform: skewX(-150deg) scale(1);
  }

  &--bottomright {
    right: 100%;
    transform: skewX(150deg) scale(1);
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.page__container {
  margin: 0 2rem;
  max-width: 40rem;
  text-align: center;
  position: relative;
  z-index: 1;

  .home & {
    width: calc(100% - 6rem);
    max-width: 50rem;
    @media (min-width: 768px) {
      max-width: 62.5rem;
    }
  }

  .work & {
    max-width: 70rem;
    position: relative;
    justify-content: flex-start;
    // margin-top: 3rem;
  }

  .services & {
    max-width: 70rem;
  }

  .contact & {
  }

  @media (max-width: 768px) {
    margin: 1rem;
  }
}

.about {
  text-align: center;
}

.grid {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
}

.grid__item {
  flex-basis: 33.3333%;
  position: relative;
  padding: 1rem;
  box-sizing: border-box;

  // @media (max-width: 768px) {
  //   flex-basis: 50%;
  // }

  @media (max-width: 480px) {
    flex-basis: 100%;
  }
}

.grid__img {
  width: 100%;
  max-width: 70%;
  padding: 1rem 0;

  @media (max-width: 768px) {
    padding: 2rem 0;
  }
}

.services-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
}

.services-list__item {
  flex-basis: 33.3333%;
  position: relative;
  padding: 1rem;
  box-sizing: border-box;
  color: #fff;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 4rem;
  font-weight: 700;
  font-size: 1rem;

  // @media (max-width: 768px) {
  //   flex-basis: 50%;
  // }

  @media (max-width: 480px) {
    flex-basis: 100%;
  }
}

.home__title {
  animation: text-flicker 6s linear infinite;
}

@keyframes text-flicker {
  0% {
    opacity: 0.1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }

  2% {
    opacity: 1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  8% {
    opacity: 0.1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  9% {
    opacity: 1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  12% {
    opacity: 0.1;
    text-shadow: 0px 0px rgba(242, 22, 22, 1);
  }
  20% {
    opacity: 1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  25% {
    opacity: 0.3;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  30% {
    opacity: 1;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }

  70% {
    opacity: 0.7;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }

  72% {
    opacity: 0.2;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }

  77% {
    opacity: 0.9;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
  100% {
    opacity: 0.9;
    text-shadow: 0px 0px 29px rgba(242, 22, 22, 1);
  }
}
