body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;

  @media (max-width: 768px) {
    overflow: auto;
  }
}

h1,
h2,
h3,
h4,
h4,
h5 {
  color: #fff;
}

p {
  color: #fff;
  font-size: 1.1rem;
}

a {
  color: #fff;
}

.svg,
svg {
  width: 100%;
  height: auto;
}
